.page-universe {
  height: 100vh;
  background-color: #0c040e;
  background-image: url(../../assets/universe_bg2.jpeg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  #meteor {
    position: absolute;
    z-index: 99;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .ufo-display-box {
    position: relative;
    transition: all 1s linear;

    &.hide {
      transform: translateY(-666px);
    }

    .ufo-bottom {
      background-image: url(../../assets/ufo-bottom.png);
      width: 760px;
      height: 114px;
      margin: auto;
    }

    .tech-box {
      position: absolute;
      z-index: 999;
      left: 0;
      right: 0;
      top: 114px;
      margin: auto;
      width: 900px;
      height: 500px;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 10px;
        background-image: url(../../assets/frame-top.png);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
  
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 50px;
        background-image: url(../../assets/frame-bottom.png);
        background-size: 100% 100%;
        transform: translateY(-10px);
      }

      &-close-btn {
        position: absolute;
        right: 0;
        top: 0px;
        z-index: 9;
        width: 50px;
        height: 50px;
        background-image: url(../../assets/frame-close.png);
        background-size: contain;
        cursor: pointer;

        &:active {
          opacity: .75;
        }
      }
  
      &-lr {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: #f5f5f5;
  
        &::before {
          content: "";
          display: block;
          width: 50px;
          height: 100%;
          background-image: url(../../assets/frame-lr.png);
          background-position-x: 0px;
        }
  
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          display: inline-block;
          width: 50px;
          height: 100%;
          background-image: url(../../assets/frame-lr.png);
          background-position-x: 50px;
        }
      }

      > iframe {
        position: absolute;
        top: 10px;
        left: 50px;
        width: 800px;
        height: 500px;
      }

      .contact-info-box {
        background-color: #f5f5f5;
      }

      .tech-btn-group {
        position: absolute;
        z-index: 9;
        right: 100px;

        > .tech-btn {
          display: inline-block;
          width: 95px;
          height: 35px;
          background-image: url(../../assets/button.png);
          background-size: contain;
          cursor: pointer;
          text-align: center;
          line-height: 35px;
          color: white;
          opacity: .8;
  
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .ufo-show-btn {
    width: 200px;
    height: 123px;
    background-image: url(../../assets/ufo.png);
    background-size: contain;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    z-index: 99;

    &::before {
      content: "";
      width: 40px;
      height: 40px;
      background-image: url(../../assets/hand.png);
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      margin: auto;
      animation: click .5s ease-in-out infinite alternate;
    }

    &.show {
      cursor: pointer;
      opacity: 1;
      transition: all .1s 1.2s ease-in;
    }
  }

  .universe-bottom {
    position: absolute;
    z-index: 9;
    width: 100vw;
    height: 500px;
    background-image: url(../../assets/plant-bottom.png);
    bottom: 0;
    background-size: 100% 100%;

    &::after {
      content: "";
      width: 300px;
      height: 360px;
      background-image: url(../../assets/penguin.png);
      background-size: contain;
      position: absolute;
      z-index: 9;
      bottom: 0;
      right: 20px;
    }
  }

  #planets {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@keyframes click {
  from {
    transform: rotateX(0);
  }
  to {
    transform: rotateX(-45deg);
  }
}
