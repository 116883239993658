.page-atmosphere {
  position: relative;
  height: 100vh;
  background-image: linear-gradient(to bottom, #010101, #470f50);
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vw;
    background-image: url(../../assets/star.png);
    animation: starRotate 120s linear infinite;
  }

  &::after {
    content: "";
    width: 347px;
    height: 181px;
    background-image: url(../../assets/planet.png);
    background-size: contain;
    position: absolute;
    top: 100px;
    left: 200px;
  }

  .black-cloud {
    position: absolute;
    z-index: 9;
    top: -180px;
    left: 0;
    width: 100vw;
    height: 400px;
    background-image: url(../../assets/black-cloud.png);
  }

  .work-box {
    position: absolute;
    z-index: 99;
    top: 150px;
    left: 500px;

    .work-bar {
      width: 523px;
      height: 177px;
      animation: switch 1s linear infinite alternate;
    }
  
    .black-board {
      position: absolute;
      top: 120px;
      left: -20px;
      width: 600px;
      height: 600px;
      background-image: url(../../assets/blackboard.png);
      background-size: 100% 100%;
      padding: 180px 40px 50px 30px;
      transform-origin: 50% 0;
      transform: rotate(20deg);
      animation: swing 10s ease-in-out infinite alternate;
    }
  }

  #earth {
    position: absolute;
    bottom: 50px;
    right: 100px;

    &::before {
      content: "";
      position: absolute;
      top: -220px;
      left: -30px;
      width: 200px;
      height: 184px;
      background-image: url(../../assets/space-ship.png);
      background-size: contain;
      animation: satellite 10s linear infinite alternate;
    }

    &::after {
      content: "";
      position: absolute;
      top: -100px;
      right: 30px;
      width: 100px;
      height: 91px;
      background-image: url(../../assets/satellite.png);
      background-size: contain;
      animation: satellite 5s linear infinite alternate;
    }
  }
}

@keyframes starRotate {
  from {
    transform: rotate(0);
  }
  to
  {
    transform: rotate(360deg);
  }
}

@keyframes switch {
  from {
    background-image: url(../../assets/work-light1.png);
  }
  to {
    background-image: url(../../assets/work-light2.png);
  }
}

@keyframes swing {
  from {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-5deg);
  }
}

@keyframes satellite {
  from {
    transform: rotate(5deg) translate(10px, 10px);
  }
  to {
    transform: rotate(-5deg) translate(-10px, -10px);
  }
}