.page-home {
  height: 100vh;
  background-image: linear-gradient(to bottom, #1ba2a8, #f9f0dd 45%);
  position: relative;

  &_sea {
    position: absolute;
    left: 0;
    bottom: 125px;
    width: 100%;
    height: 136px;
    background-image: url(../../assets/sea.png);
    background-size: contain;
  }

  &_land {
    position: absolute;
    left: 0;
    bottom: 156px;
    width: 1099px;
    height: 203px;
    background-image: url(../../assets/land.png);
    background-repeat: no-repeat;
    background-size: contain;

    &::before {
      content: "";
      z-index: 9;
      position: absolute;
      left: 770px;
      bottom: 230px;
      display: block;
      width: 100px;
      height: 44px;
      background-image: url(../../assets/seagulls.png);
      background-size: contain;
    }

    &::after {
      content: "";
      z-index: 8;
      position: absolute;
      display: block;
      left: 700px;
      bottom: 98px;
      width: 100px;
      height: 150px;
      background-image: url(../../assets/lighthouse.png);
      background-size: contain;
    }
  }

  &_land2 {
    position: absolute;
    right: 300px;
    bottom: 218px;
    width: 144px;
    height: 393px;
    background-image: url(../../assets/land2.png);
    background-repeat: no-repeat;
    background-size: contain;

    &::before {
      content: "";
      position: absolute;
      top: 150px;
      z-index: 8;
      display: block;
      width: 100px;
      height: 44px;
      transform: rotate(-10deg);
      background-image: url(../../assets/seagulls.png);
      background-size: contain;
    }

    &::after {
      content: "";
      position: absolute;
      left: 100px;
      top: 180px;
      z-index: 8;
      display: block;
      width: 100px;
      height: 44px;
      transform: rotate(-20deg);
      background-image: url(../../assets/seagulls.png);
      background-size: contain;
    }
  }

  &_darkcloud {
    position: absolute;
    bottom: 300px;
    left: 0;
    width: 100%;
    height: 262px;
    background-image: url(../../assets/dark-cloud.png);
    background-repeat: repeat-x;
    background-size: contain;
  }

  &_paperplane {
    position: absolute;
    z-index: 1000;
    bottom: 100px;
    right: -150px;
    width: 150px;
    height: 150px;
    background-image: url(../../assets/paper-plane.png);
    background-size: contain;
    animation: fly 30s  linear infinite;
  }

  canvas {
    position: absolute;
    bottom: 0;
    left: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 300px;
    width: 100%;

    &#floating {
      z-index: 9999;
      bottom: -10px;
    }
  
    &#glass {
      z-index: 999;
    }
  }


  .bottom-glass {
    background-image: url(../../assets/glass.png);
    background-size: contain;
    z-index: 99;
  }
}

@keyframes fly {
  0% {
    transform:  translate(0, 0) skew(-40deg, 15deg);
  }
  25% {
    transform: translate(-25vw, -100px) skew(20deg, -25deg) scale(0.7) rotate(20deg);
  }
  50% {
    transform: translate(-50vw, -40px) skew(10deg, -15deg) scale(1.2) rotate(-10deg);
  }

  75% {
    transform: translate(-75vw, 10px) skew(-45deg, 10deg) scale(1.3);
  }
  100% {
    transform: translate(-110vw, -50px) skew(-20deg, 5deg) scale(.5);
  }
}