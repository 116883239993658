.comp-experience {
  color: white;
  padding: 0 20px;
  height: 360px;
  overflow: auto;

  &_wrap {

    > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.comp-experience-record {
  display: flex;
  align-items: center;

  &_logo {
    width: 120px;
    height: 80px;
    background-color: white;
    object-fit: contain;
    border-radius: 10px;
    user-select: none;
  }

  &_info {
    margin-left: 20px;
    font-size: 18px;
    flex: 1;

    .info-name {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 5px;

      &::after {
        content: attr(data-name);
        font-size: 12px;
        padding: 4px;
        background-color: #333;
        margin-left: 5px;
        vertical-align: middle;
        border-radius: 4px;
      }
    }

    .project-detail {
      float: right;
      font-size: 14px;
      padding: 2px 4px;
      margin-left: 20px;
      border: 1px solid white;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      transition: all .2s ease-in-out;

      &>i {
        margin-right: 5px;
      }

      &:hover {
        background-color: white;
        color:#333;
      }
    }
  }
}