.comp-contact-info {
  width: calc(100% - 100px);
  height: 100%;
  position: absolute;
  left: 50px;
  bottom: -10px;
  padding: 25px 50px;

  &::after {
    content: "";
    width: 441px;
    height: 211px;
    background-image: url(../../assets/robot.png);
    background-size: contain;
    position: absolute;
    right: 0;
    bottom: -40px;
  }

  &_record {
    font-size: 40px;
    color: #5c5c5c;
    cursor: pointer;
    opacity: .7;

    a, span {
      font-size: 20px;
      color: #555;
      text-decoration: none;
    }

    > * {
      vertical-align: middle;
    }

    > *:not(:last-child) {
      margin-right: 20px;
    }

    &:hover {
      opacity: 1;
    }

    .wechat {

      &:hover {
        &::after {
          content: "";
          width: 200px;
          height: 200px;
          background-image: url(../../assets/wechat.jpg);
          background-size: contain;
          position: absolute;
          top: 50px;
          right: 50px;
        }
      }
    }

    .qq {

      &:hover {
        &::after {
          content: "";
          width: 200px;
          height: 238px;
          background-image: url(../../assets/qq.jpg);
          background-size: contain;
          position: absolute;
          top: 50px;
          right: 50px;
        }
      }
    }

    .linkedin {

      &:hover {
        &::after {
          content: "";
          width: 200px;
          height: 200px;
          background-image: url(../../assets/linkedin.jpg);
          background-size: contain;
          position: absolute;
          top: 50px;
          right: 50px;
        }
      }
    }
  }

  .current-pos {
    position: absolute;
    bottom: 20px;
    font-size: 16px;

    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url(../../assets/location.png);
      background-size: contain;
      vertical-align: bottom;
    }
  }
}