.comp-progress-bar {
  background: gainsboro;
  width: 100%;
  height: 2.5em;
  padding: 0.5em;
  position: relative;
  border-radius: 50px;
  box-sizing: border-box;
  box-shadow: 0 0 1px 0 rgba(#000, 0.75) inset, 0 0 4px 0 rgba(#000, 0.5);
  
  &-wrapper {
    background: #000;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.15) inset, 0 0 1px 3px rgba(white, 0.75) inset;
    background: transparent;

    &:before{
      content: "";
      border-radius: 50px;
      display: block;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 1px rgba(#000, 0.3) inset, 0 0 5px 2px rgba(#FFF, 0.8) inset;
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }
    &:after{
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      width: 96%;
      left: 50%;
      margin-left: -48%;
      border-radius: 20px;
      height: 10px;
      background: linear-gradient(to bottom, rgba(white, 0.85) 30%, transparent 120%);
    }
  }
  
  &-cloth {
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(-90deg, violet, mix(violet, deepskyblue, 20%), mix(limegreen, yellow, 70%), yellow, orange, mix(red, deeppink, 20%));
    transition: all 1s;
    
    &:before{
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background-image: repeating-linear-gradient(-45deg, transparent, transparent 10px, #fff 10px, #fff 20px);
      opacity: 0.75;
      mix-blend-mode: overlay;
      border-radius: 50px;
    }
    
  }
  
  &:hover {
    .comp-progress-bar-cloth {
      width: 100%
    }
  }
}