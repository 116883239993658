.comp-panel {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #333;

  &.hide {
    display: none;
  }
}