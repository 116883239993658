.page-sky {
  position: relative;
  height: 100vh;
  background-image: linear-gradient(to bottom, #470f50, #1ba2a8);

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-image: url(../../assets/star-flow.png);
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .mac {
    position: absolute;
    top: 0;
    bottom: -20px;
    right: 300px;
    width: 1088px;
    height: 829px;
    margin: auto;
    background-image: url(../../assets/mac.png);
    background-size: contain;

    &:after {
      content: "";
      background-color: #2b3a41;
      position: absolute;
      top: 45px;
      left: 45px;
      width: 1000px;
      height: 565px;
    }

    .show-content {
      z-index: 9;;
    }
  }

  .hot-air-balloon {
    width: 400px;
    height: 574px;
    background-image: url(../../assets/hot-air-balloon.png);
    background-size: contain;
    position: absolute;
    z-index: 9;
    bottom: 160px;
    right: 10px;
    animation: hotAirBallFloat linear 3s infinite alternate;
  }

  .cloud {
    position: absolute;
    z-index: 99;
    bottom: -10px;
    width: 100%;
    height: 320px;
    background-image: url(../../assets/sky-cloud.png);
    background-size: contain;
    background-repeat-y: no-repeat;
    animation: yasuo 8s linear infinite alternate;
  }
}

@keyframes yasuo {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(.8);
  }
}

@keyframes hotAirBallFloat {
  from {
    transform: translate(10px, 30px);
  }
  to {
    transform: translate(-10px, -10px);
  }
}