.stage {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  // .my-home {
  //   position: fixed;
  //   top: 25px;
  //   left: 50px;
  //   z-index: 1;
  //   width: 100px;
  //   height: 75px;
  //   background-image: url(./assets/home.png);
  //   transition: opacity 1s ease-in-out;
  //   opacity: 1;
  //   cursor: pointer;

  //   &.hide {
  //     opacity: 0;
  //     pointer-events: none;
  //   }
  // }

  .my-nav-bar {
    position: fixed;
    top: 25px;
    right: 50px;
    z-index: 1;
  }

  .my-story {
    transition: transform 1s ease-in-out;

    &.home {
      transform: translateY(-300vh);
    }

    &.sky {
      transform: translateY(-200vh);
    }

    &.atmosphere {
      transform: translateY(-100vh);
    }

    &.universe {
      transform: translateY(0);
    }

  }

  .my-rocket {
    position: absolute;
    left: 200px;
    transition: top 1s ease-in-out;

    &.at-home {
      top: calc(400vh - 450px);
    }

    &.at-sky {
      top: calc(300vh - 600px);
    }

    &.at-atmosphere {
      top: calc(200vh - 700px);
    }

    &.at-universe {
      top: calc(100vh - 800px);
    }
  }

  .my-project-detail {
    margin: auto;
    padding: 20px;
    width: 50%;
    height: 70%;
    background-color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    border-radius: 10px;

    & * {
      word-break: break-all;
    }
  }

  .fork-me {
    width: 150px;
    height: 150px;
    background-image: url(./assets/forkme.png);
    background-size: contain;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    cursor: pointer;
    display: block;
  }

  .icp-beian {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
    font-size: 16px;

    a {
      color: inherit;
      text-decoration: none;
    }

    #blog {
      background: linear-gradient(to right, #ff3e18 16.66%,#fc9a00 16.66%, #fc9a00 33.32%, #ffd800 33.32%, #ffd800 49.98%, #39ea7c 49.98%, #39ea7c 66.64%, #0bb2ff 66.64%, #0bb2ff 80.3%, #985aff 80.3%);
      background-clip: text;
      color: transparent;
      font-weight: bold;
    }

    .beian {
      
      &::before {
        content: "";
        display: inline-block;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        background-image: url(./assets/icp.png);
        background-size: contain;
        vertical-align: text-bottom;
      }
    }
  }
}

body {
  // font-family: Georgia, "Times New Roman", "Microsoft YaHei", "微软雅黑", STXihei, "华文细黑", serif;
  font-size: 14px;
  cursor: url(./assets/hand-cursor.png), pointer;
}


/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
