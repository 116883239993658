.comp-pure-text-output {

  > span {
    animation-name: fadeIn;
    animation-duration: .2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    visibility: hidden;
  }
}

@keyframes fadeIn {
  from {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}
