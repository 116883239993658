body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

@charset "UTF-8";
@font-face
{ 
    font-family: "pictonic";
    src: url('./font/pictonic.eot');
    src: url('./font/pictonic.eot?#iefix') format('embedded-opentype'),
        url('./font/pictonic.ttf') format('truetype'),
        url('./font/pictonic.woff') format('woff'),
        url('./font/pictonic.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
 
.pictonic
{
	font-family: "pictonic";
	font-weight: normal; font-style: normal;
	-webkit-font-smoothing:  antialiased !important;
	-moz-font-smoothing:  antialiased !important;
	font-smoothing:  antialiased !important;
	line-height:1em;
}
 
a.pictonic, span.pictonic, small.pictonic {
	display: -moz-inline-stack;
	display:inline-block;
	zoom: 1;
	*display: inline;
}
 
[class^="icon-"], [class*=" icon-"]
{
	font-family: "pictonic";
	font-weight: normal; font-style: normal;
	-webkit-font-smoothing:  antialiased !important;
	-moz-font-smoothing:  antialiased !important;
	font-smoothing:  antialiased !important;
	line-height:1em;
}
 
a[class^="icon-"], a[class*=" icon-"], span[class^="icon-"], span[class*=" icon-"], small[class^="icon-"], small[class*=" icon-"] {
	display: -moz-inline-stack;
	display:inline-block;
	zoom: 1;
	*display: inline;
}
  
.icon-html5-01:before { content:""; } 
.icon-html5-02:before { content:""; } 
.icon-css3-01:before { content:""; } 
.icon-css3-02:before { content:""; } 
.icon-audiobox-01:before { content:""; } 
.icon-box-com:before { content:""; } 
.icon-audiobox-02:before { content:""; } 
.icon-pictonic-02:before { content:""; } 
.icon-pictonic-01:before { content:""; } 
.icon-angularjs:before { content:""; } 
.icon-codepen-02:before { content:""; } 
.icon-codepen-01:before { content:""; } 
.icon-bandcamp:before { content:""; } 
.icon-shopify:before { content:""; } 
.icon-prog-pylons-02:before { content:""; } 
.icon-google-currents:before { content:""; } 
.icon-prog-cherrypy:before { content:""; } 
.icon-prog-backbonejs:before { content:""; } 
.icon-prog-pylons:before { content:""; } 
.icon-prog-flask:before { content:""; } 
.icon-prog-cakephp:before { content:""; } 
.icon-rus-yandex-01:before { content:""; } 
.icon-rus-vk-02:before { content:""; } 
.icon-rus-vk-01:before { content:""; } 
.icon-rus-yandex-02:before { content:""; } 
.icon-rus-habrahbr:before { content:""; } 
.icon-chn-wechat:before { content:""; } 
.icon-chn-tencent-02:before { content:""; } 
.icon-chn-tencent-01:before { content:""; } 
.icon-chn-tencent-03:before { content:""; } 
.icon-chn-weibo:before { content:""; } 
.icon-chn-renren:before { content:""; } 
.icon-livejournal:before { content:""; } 
.icon-wikipedia:before { content:""; } 
.icon-icq:before { content:""; } 
.icon-mailru:before { content:""; } 
.icon-skydrive:before { content:""; } 
.icon-googledrive-02:before { content:""; } 
.icon-googledrive-01:before { content:""; } 
.icon-dropbox:before { content:""; } 
.icon-adb-speedgrade:before { content:""; } 
.icon-adb-acrobat:before { content:""; } 
.icon-adb-audition:before { content:""; } 
.icon-adb-contribute:before { content:""; } 
.icon-adb-bridge:before { content:""; } 
.icon-adb-dreamweaver:before { content:""; } 
.icon-adb-encore:before { content:""; } 
.icon-adb-fireworks:before { content:""; } 
.icon-adb-flashbldr:before { content:""; } 
.icon-adb-flash:before { content:""; } 
.icon-adb-illustrator:before { content:""; } 
.icon-adb-indesign:before { content:""; } 
.icon-adb-lightroom:before { content:""; } 
.icon-adb-onlocation:before { content:""; } 
.icon-adb-photoshop:before { content:""; } 
.icon-adb-prelude:before { content:""; } 
.icon-adb-premiere:before { content:""; } 
.icon-adb-framemaker:before { content:""; } 
.icon-adb-captivate:before { content:""; } 
.icon-adb-aftereffects:before { content:""; } 
.icon-adb-robohelp:before { content:""; } 
.icon-corel-draw:before { content:""; } 
.icon-corel-painter:before { content:""; } 
.icon-_x33_dsmax:before { content:""; } 
.icon-softimage:before { content:""; } 
.icon-lightwave:before { content:""; } 
.icon-cinema4d:before { content:""; } 
.icon-maya:before { content:""; } 
.icon-autocad:before { content:""; } 
.icon-autocad-lt:before { content:""; } 
.icon-modo:before { content:""; } 
.icon-sketchup-01:before { content:""; } 
.icon-sketchup-02:before { content:""; } 
.icon-solidworks:before { content:""; } 
.icon-houdini:before { content:""; } 
.icon-blender:before { content:""; } 
.icon-vue:before { content:""; } 
.icon-zbrush:before { content:""; } 
.icon-os-ios-dark:before { content:""; } 
.icon-os-osx_1_:before { content:""; } 
.icon-os-apple:before { content:""; } 
.icon-os-ios:before { content:""; } 
.icon-os-win-01:before { content:""; } 
.icon-os-win-02:before { content:""; } 
.icon-os-win-03:before { content:""; } 
.icon-os-win-04:before { content:""; } 
.icon-os-linux_1_:before { content:""; } 
.icon-lin-debian:before { content:""; } 
.icon-os-osx-dark:before { content:""; } 
.icon-os-apple-dark:before { content:""; } 
.icon-os-win-01-dark:before { content:""; } 
.icon-os-win-02-dark:before { content:""; } 
.icon-os-win-03-dark:before { content:""; } 
.icon-os-linux:before { content:""; } 
.icon-os-win-04-dark:before { content:""; } 
.icon-pl-dart:before { content:""; } 
.icon-pl-clojure:before { content:""; } 
.icon-pl-groovy-01:before { content:""; } 
.icon-pl-groovy-02:before { content:""; } 
.icon-dbs-mysql:before { content:""; } 
.icon-dbs-postgresql:before { content:""; } 
.icon-dbs-sqlserver:before { content:""; } 
.icon-dbs-sqlite:before { content:""; } 
.icon-dbs-mongodb:before { content:""; } 
.icon-dbs-riak:before { content:""; } 
.icon-dbs-couchdb:before { content:""; } 
.icon-dbs-redis:before { content:""; } 
.icon-dbs-hadoop:before { content:""; } 
.icon-dbs-hbase:before { content:""; } 
.icon-dbs-cassandra:before { content:""; } 
.icon-dbs-couchbase-01:before { content:""; } 
.icon-dbs-couchbase-02:before { content:""; } 
.icon-dbs-neo4j:before { content:""; } 
.icon-vc-svn:before { content:""; } 
.icon-vc-git:before { content:""; } 
.icon-lin-centos:before { content:""; } 
.icon-lin-redhat:before { content:""; } 
.icon-lin-fedora:before { content:""; } 
.icon-lin-ubuntu:before { content:""; } 
.icon-vc-fossil:before { content:""; } 
.icon-vc-bazaar:before { content:""; } 
.icon-vc-bitbucket-02:before { content:""; } 
.icon-vc-bitbucket-01:before { content:""; } 
.icon-vc-mercurial:before { content:""; } 
.icon-brw-chrome:before { content:""; } 
.icon-brw-safari-01:before { content:""; } 
.icon-brw-safari-02:before { content:""; } 
.icon-brw-firefox:before { content:""; } 
.icon-brw-explorer:before { content:""; } 
.icon-brw-opera:before { content:""; } 
.icon-zerply:before { content:""; } 
.icon-social-bakers:before { content:""; } 
.icon-android:before { content:""; } 
.icon-prog-actionscript:before { content:""; } 
.icon-prog-matlab:before { content:""; } 
.icon-prog-delphi02:before { content:""; } 
.icon-prog-golang01:before { content:""; } 
.icon-prog-golang02:before { content:""; } 
.icon-prog-haskell:before { content:""; } 
.icon-prog-scala:before { content:""; } 
.icon-prog-lisp:before { content:""; } 
.icon-prog-basic:before { content:""; } 
.icon-prog-php02:before { content:""; } 
.icon-prog-c:before { content:""; } 
.icon-prog-ruby:before { content:""; } 
.icon-prog-csharp:before { content:""; } 
.icon-prog-cplusplus:before { content:""; } 
.icon-prog-ccplusplus:before { content:""; } 
.icon-prog-js01:before { content:""; } 
.icon-prog-objc:before { content:""; } 
.icon-prog-js02:before { content:""; } 
.icon-prog-java:before { content:""; } 
.icon-prog-dotnet:before { content:""; } 
.icon-prog-php01:before { content:""; } 
.icon-prog-vbnet:before { content:""; } 
.icon-prog-aspnet:before { content:""; } 
.icon-prog-visualstudio:before { content:""; } 
.icon-prog-python:before { content:""; } 
.icon-prog-lua01:before { content:""; } 
.icon-prog-lua02:before { content:""; } 
.icon-prog-air01:before { content:""; } 
.icon-prog-air02:before { content:""; } 
.icon-prog-jquery:before { content:""; } 
.icon-prog-mootools:before { content:""; } 
.icon-prog-nodejs01:before { content:""; } 
.icon-prog-nodejs02:before { content:""; } 
.icon-prog-codeign:before { content:""; } 
.icon-prog-expression:before { content:""; } 
.icon-prog-symfony:before { content:""; } 
.icon-prog-yii:before { content:""; } 
.icon-prog-zendfrm:before { content:""; } 
.icon-prog-drupal:before { content:""; } 
.icon-prog-joomla:before { content:""; } 
.icon-prog-wordpress:before { content:""; } 
.icon-prog-magento:before { content:""; } 
.icon-prog-django:before { content:""; } 
.icon-prog-rails:before { content:""; } 
.icon-prog-erlang:before { content:""; } 
.icon-prog-coffeescr:before { content:""; } 
.icon-prog-perl:before { content:""; } 
.icon-prog-fortran:before { content:""; } 
.icon-prog-bash01:before { content:""; } 
.icon-prog-bash02:before { content:""; } 
.icon-prog-cobol:before { content:""; } 
.icon-prog-delphi01:before { content:""; } 
.icon-github-02:before { content:""; } 
.icon-soundcloud:before { content:""; } 
.icon-github-01:before { content:""; } 
.icon-rdio:before { content:""; } 
.icon-svpply-01:before { content:""; } 
.icon-grooveshark:before { content:""; } 
.icon-svpply-02:before { content:""; } 
.icon-spotify:before { content:""; } 
.icon-instagram:before { content:""; } 
.icon-creativesloth:before { content:""; } 
.icon-xing:before { content:""; } 
.icon-yammer:before { content:""; } 
.icon-yahoo:before { content:""; } 
.icon-wordpress:before { content:""; } 
.icon-xanga:before { content:""; } 
.icon-weblink_2:before { content:""; } 
.icon-windows:before { content:""; } 
.icon-weblink_1:before { content:""; } 
.icon-vimeo:before { content:""; } 
.icon-typepad:before { content:""; } 
.icon-twitter-2:before { content:""; } 
.icon-twitter-3:before { content:""; } 
.icon-tumblr:before { content:""; } 
.icon-twitter-1:before { content:""; } 
.icon-slash_dot:before { content:""; } 
.icon-stumble_upon:before { content:""; } 
.icon-technorati:before { content:""; } 
.icon-skype:before { content:""; } 
.icon-sina:before { content:""; } 
.icon-share:before { content:""; } 
.icon-reddit:before { content:""; } 
.icon-plaxo:before { content:""; } 
.icon-rss:before { content:""; } 
.icon-pinterest:before { content:""; } 
.icon-picasa:before { content:""; } 
.icon-paypal:before { content:""; } 
.icon-odnoklassniki:before { content:""; } 
.icon-orkut:before { content:""; } 
.icon-newsvine:before { content:""; } 
.icon-news_for_gamers:before { content:""; } 
.icon-voxopolis:before { content:""; } 
.icon-myspace:before { content:""; } 
.icon-mail:before { content:""; } 
.icon-linkedin:before { content:""; } 
.icon-last_fm:before { content:""; } 
.icon-kaboodle:before { content:""; } 
.icon-instapaper:before { content:""; } 
.icon-imgur:before { content:""; } 
.icon-identi-ca:before { content:""; } 
.icon-hyves:before { content:""; } 
.icon-hi5:before { content:""; } 
.icon-friendfeed:before { content:""; } 
.icon-google__x2B_:before { content:""; } 
.icon-google:before { content:""; } 
.icon-gmail:before { content:""; } 
.icon-fresqui:before { content:""; } 
.icon-foursquare_2:before { content:""; } 
.icon-foursquare_1:before { content:""; } 
.icon-forrst:before { content:""; } 
.icon-formspring:before { content:""; } 
.icon-flickr:before { content:""; } 
.icon-feedburner:before { content:""; } 
.icon-facebook:before { content:""; } 
.icon-evernote:before { content:""; } 
.icon-dribbble:before { content:""; } 
.icon-digg:before { content:""; } 
.icon-deviantart:before { content:""; } 
.icon-design-float:before { content:""; } 
.icon-delicious:before { content:""; } 
.icon-bookmark:before { content:""; } 
.icon-blip:before { content:""; } 
.icon-blogger:before { content:""; } 
.icon-blinklist:before { content:""; } 
.icon-behance:before { content:""; } 
.icon-bebo:before { content:""; } 
.icon-baidu:before { content:""; } 
.icon-apple:before { content:""; } 
.icon-amazon:before { content:""; } 
.icon-aim:before { content:""; } 
.icon-youtube:before { content:""; } 
.icon-netlog:before { content:""; }