@mixin ready-cloud-pseudo {
  content: '';
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../../assets/ready-cloud.png);
  background-size: contain;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@mixin flight-cloud-pseudo {
  content: '';
  display: block;
  width: 98px;
  height: 44px;
  background-image: url(../../assets/flight-cloud.png);
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.comp-rocket {
  bottom: 430px;

  &_body {
    position: absolute;
    z-index: 99;
    width: 174px;
    height: 403px;
    background-image: url(../../assets/rocket.png);
    background-size: contain;

    &.ready {
      
      &::before {
        @include ready-cloud-pseudo;
        animation: readyCloudLeft 4s linear 1s infinite forwards;
      }

      &::after {
        @include ready-cloud-pseudo;
        animation: readyCloudRight 3s linear 2s infinite forwards;
      }
    }

    &.flight {

      &::before {
        @include flight-cloud-pseudo;
        animation: flightCloud .75s ease-out infinite;
      }
    }

    &.down {
      transform: rotate(180deg);
    }

    .home-btn {
      position: absolute;
      left: 0;
      right: 0;
      top: 105px;
      margin: auto;
      width: 60px;
      height: 60px;
      background-image: url(../../assets/home.png);
      background-size: contain;
      opacity: .2;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &.hide {
        display: none;
      }
    }
  }
}

@keyframes astronautFloat {
  33% {
    transform: rotate(-45deg);
  }
  66% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes flightCloud {
  0% {
    opacity: 1;
    transform: scale(.5);
  }
  100% {
    opacity: 0;
    transform: translateY(100px) scale(2) ;
  }
}

@keyframes readyCloudLeft {
  0% {
    opacity: 1;
  }
  20%{
    opacity: 1;
    transform: translate(-50px, -40px) rotate(160deg);
  }
  100% {
    opacity: 0;
    transform: translate(-100px, -220px) rotate(360deg) scale(2);
  }
}

@keyframes readyCloudRight {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
    transform: translate(60px, -50px) rotate(-160deg);
  }
  100% {
    opacity: 0;
    transform: translate(120px, -240px) rotate(-360deg) scale(2);
  }
}