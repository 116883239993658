.comp-assets-loading {
  background: gray;
  color: white;
  width: 100vw;
  height: 100vh;
  position: relative;

  .loading-status-box {
    width: 50%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);

    p {
      font-size: 24px;
    }
  }
}